<template>
  <ion-item :lines="lines" class="ion-no-padding">
    <ion-avatar slot="start" v-if="false">
      <img :src="user.profile_photo_url
          ? user.profile_photo_url
          : 'https://borym.s3.amazonaws.com/media/soft_green_light.png'
        " v-if="false"/>
    </ion-avatar>
    <ion-label>
      <div class="h6">
        {{ user.alias ? `${user.alias} (${user.fullname})` : user.fullname }}
      </div>
      <h6>{{ user.email }}</h6>
    </ion-label>
    <ion-label slot="end" class="ion-text-right ion-no-margin"> </ion-label>
  </ion-item>
</template>

<script>
import { IonItem, IonLabel, IonAvatar } from "@ionic/vue";

export default {
  props: {
    user: {
      type: Object,
      required: true,
    },
    lines: {
      type: String,
      default: "full",
    },
  },

  components: {
    IonItem,
    IonAvatar,
    IonLabel,
  },

  computed: {},
};
</script>

<style scoped>
ion-badge {
  font-weight: 300;
}

ion-item {
  --padding-bottom: 8px;
  --padding-top: 8px;
  --border-color: var(--light-line-color);
}
</style>