<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <router-link to="/" custom v-slot="{ }">
            <a @click="cancelModal">Cancel</a>
          </router-link>
        </ion-buttons>
        <ion-title>Guest Search</ion-title>
      </ion-toolbar>

      <!-- Search Toolbar -->
      <ion-toolbar>
        <!-- First Name Search -->
        <ion-searchbar v-model="search.first_name" debounce="500" class="ion-no-padding"
          placeholder="First Name"></ion-searchbar>

        <!-- Last Name Search -->
        <ion-searchbar v-model="search.last_name" debounce="500" class="ion-no-padding"
          placeholder="Last Name"></ion-searchbar>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true">
      <ion-list class="ion-padding-start ion-padding-end">
        <user-list-item v-for="user in users" v-bind:key="user.id" :user="user"
          @click="selectUser(user)"></user-list-item>
      </ion-list>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonSearchbar,
  IonButtons,
  IonContent,
  IonList,
  modalController
} from "@ionic/vue";
import UserListItem from "@/components/users/UserListItem.vue"

export default {
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonSearchbar,
    IonButtons,
    IonContent,
    IonList,
    UserListItem
  },

  data() {
    return {
      search: {
        first_name: null,
        last_name: null,
        type: "guest",
        role: "mother"
      },
      users: [],
      user: {},
    };
  },

  watch: {
    "search.first_name": async function () {
      this.fetchUsers();
    },

    "search.last_name": async function () {
      this.fetchUsers();
    },
  },

  methods: {
    /**
     * Fetch Users based on this.search
     * If no first or last_name exist, then do not search
     */
    async fetchUsers() {
      let queryString = Object.keys(this.search)
        .filter(key => this.search[key] != null && this.search[key] !== '')
        .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(this.search[key])}`)
        .join('&');

      // Only search if First Name or Last Name is provided
      if (this.search.first_name || this.search.last_name) {
        await this.axios
          .get(
            `${process.env.VUE_APP_API}/users?${queryString}`
          )
          .then((response) => {
            this.users = response.data.users;
          })
          .catch((error) => { console.log(error) });
      }
      // Otherwise, clear the results
      else
        this.users = []
    },

    /**
     * Emit a Select User to the parent component with the selected user
     */
    selectUser(user) {
      this.user = user;
      this.$emit("select-user", this.user);
      return modalController.dismiss(this.user, 'select-user');
    },

    /**
     * Emit a cancel modal to the parent to close this modal
     */
    cancelModal() {
      this.$emit("cancel-modal");
      return modalController.dismiss(null, 'cancel');
    },
  },
};
</script>