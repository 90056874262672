<template>
  <base-layout-two page-title="New Reservation" page-default-back-link="/reservations">
    <div class="padding-8">
      <v-form @submit="onSubmit">
        <base-card title="Search Availability">
          <ion-grid class="ion-no-padding grid-padding">
            <ion-row>
              <!-- Property -->
              <ion-col>
                <base-input label-text="Property *">
                  <v-field name="property" v-slot="{ field }" v-model="reservation.property_id" :rules="required">
                    <select v-bind="field" @change="selectedPropertyUpdateRoomTypes()" class="regular-select">
                      <option v-for="property in properties" :key="property.id" :value="property.id">
                        {{ property.name }}
                      </option>
                    </select>
                  </v-field>
                </base-input>
                <v-error-message name="property" class="error-message">
                </v-error-message>
              </ion-col>
            </ion-row>

            <ion-row>
              <!-- Arrival Date -->
              <ion-col size="12" size-lg="4">
                <base-input label-text="Arrival Date *">
                  <v-field name="arrival_date" v-slot="{ field }" v-model="reservation.arrival_date" :rules="required">
                    <ion-input name="arrival_date" v-bind="field" placeholder="" type="date"></ion-input>
                  </v-field>
                </base-input>
                <v-error-message name="arrival_date" class="error-message">
                </v-error-message>
              </ion-col>

              <!-- Number nights -->
              <ion-col size="12" size-lg="4">
                <base-input label-text="Number Nights *">
                  <v-field name="duration" v-slot="{ field }" v-model="reservation.duration" :rules="required">
                    <select v-bind="field" class="regular-select" :disabled="!this.reservation.arrival_date">
                      <option :value="1">1 night</option>
                      <option :value="2">2 nights</option>
                      <option :value="3">3 nights</option>
                      <option :value="4">4 nights</option>
                      <option :value="5">5 nights</option>
                      <option :value="6">6 nights</option>
                      <option :value="7">7 nights</option>
                      <option :value="8">8 nights</option>
                      <option :value="9">9 nights</option>
                      <option :value="10">10 nights</option>
                      <option :value="11">11 nights</option>
                      <option :value="12">12 nights</option>
                      <option :value="13">13 nights</option>
                      <option :value="14">14 nights</option>
                      <option :value="15">15 nights</option>
                      <option :value="16">16 nights</option>
                      <option :value="17">17 nights</option>
                      <option :value="18">18 nights</option>
                      <option :value="19">19 nights</option>
                      <option :value="20">20 nights</option>
                      <option :value="21">21 nights</option>
                      <option :value="22">22 nights</option>
                      <option :value="23">23 nights</option>
                      <option :value="24">24 nights</option>
                      <option :value="25">25 nights</option>
                      <option :value="26">26 nights</option>
                      <option :value="27">27 nights</option>
                      <option :value="28">28 nights</option>
                      <option :value="29">29 nights</option>
                      <option :value="30">30 nights</option>
                      <option :value="31">31 nights</option>
                      <option :value="32">32 nights</option>
                      <option :value="33">33 nights</option>
                      <option :value="34">34 nights</option>
                      <option :value="35">35 nights</option>
                      <option :value="36">36 nights</option>
                      <option :value="37">37 nights</option>
                      <option :value="38">38 nights</option>
                      <option :value="39">39 nights</option>
                      <option :value="40">40 nights</option>
                      <option :value="41">41 nights</option>
                      <option :value="42">42 nights</option>
                      <option :value="43">43 nights</option>
                      <option :value="44">44 nights</option>
                      <option :value="45">45 nights</option>
                      <option :value="46">46 nights</option>
                      <option :value="47">47 nights</option>
                      <option :value="48">48 nights</option>
                      <option :value="49">49 nights</option>
                      <option :value="50">50 nights</option>
                      <option :value="51">51 nights</option>
                      <option :value="52">52 nights</option>
                      <option :value="53">53 nights</option>
                      <option :value="54">54 nights</option>
                      <option :value="55">55 nights</option>
                      <option :value="56">56 nights</option>
                      <option :value="57">57 nights</option>
                      <option :value="58">58 nights</option>
                      <option :value="59">59 nights</option>
                      <option :value="60">60 nights</option>
                    </select>
                  </v-field>
                </base-input>
                <v-error-message name="duration" class="error-message">
                </v-error-message>
              </ion-col>

              <ion-col size="12" size-lg="4">
                <base-input label-text="Departure Date *">
                  <v-field name="departure_date" v-slot="{ field }" v-model="reservation.departure_date"
                    :rules="required">
                    <ion-input name="departure_date" v-bind="field" placeholder="" type="date" :min="minDepartureDate"
                      disabled></ion-input>
                  </v-field>
                </base-input>
                <v-error-message name="departure_date" class="error-message">
                </v-error-message>
              </ion-col>
            </ion-row>
          </ion-grid>

          <!-- <ion-button @click="searchAvailability()">Search</ion-button> -->
          <ion-button @click="fetchAllRooms">Search*</ion-button>
          <p><i><strong>*Temporarily returns ALL rooms, whether they are occupied or not</strong></i></p>

          <section v-if="showAvailableRooms">
            <h3>Available Rooms</h3>
            <p v-if="available_rooms.length === 0"></p>
            <table width="100%" v-if="available_rooms.length > 0">
              <thead>
                <tr>
                  <td>
                    <h6>Room Type</h6>
                  </td>
                  <td>
                    <h6>Room Number</h6>
                  </td>
                  <td>
                    <h6>Retail Price</h6>
                  </td>
                </tr>
              </thead>
              <tr v-for="(room, index) in available_rooms" :key="room.id" :bgcolor="index % 2 ? '#ddd' : '#fff'">
                <td>
                  <p>{{ room.room_type.name }}</p>
                </td>
                <td>
                  <p>{{ room.number }}</p>
                </td>
                <td>
                  <p>
                    ${{
                      room.room_type.price_retail.toLocaleString("en", {
                        minimumFractionDigits: 0,
                      })
                    }}
                  </p>
                </td>
              </tr>
            </table>
          </section>
        </base-card>



        <!-- Reservation Details -->
        <base-card title="Reservation Details" v-if="showAvailableRooms && this.available_rooms.length > 0"
          class="margin-top-8">

          <ion-row>
            <!-- Guest -->
            <ion-col size="12" size-lg="12">
              <base-input label-text="Guest *" :class="!reservation.user_id ? 'placeholder-color' : ''" @click="
                () => {
                  showUserSearchModal = true;
                }
              ">
                <p>
                  {{
                    reservation.user.fullname
                    ? reservation.user.fullname
                    : "Search for Guest..."
                  }}
                </p>
              </base-input>
              <v-error-message name="guest" class="error-message">
              </v-error-message>
            </ion-col>

            <!-- Comped -->
            <ion-col size="12" size-lg="6">
              <base-input label-text="Complimentary Stay?">
                <v-field name="comped" v-slot="{ field }" v-model="reservation.comped">
                  <select v-bind="field" class="regular-select">
                    <option :value="0">No</option>
                    <option :value="1">Yes</option>
                  </select>
                </v-field>
              </base-input>
            </ion-col>

            <!-- Support Person Meals -->
            <ion-col size="12" size-lg="6">
              <base-input label-text="Support Person Meals">
                <v-field name="support_person_meals" v-slot="{ field }" v-model="reservation.support_person_meals">
                  <select v-bind="field" class="regular-select">
                    <option :value="0">No</option>
                    <option :value="1">Yes</option>
                  </select>
                </v-field>
              </base-input>
            </ion-col>
          </ion-row>

          <ion-row>
            <!-- Room Type -->
            <ion-col size="12" size-lg="6">
              <base-input label-text="Room Type">
                <v-field name="room_type" v-slot="{ field }" v-model="reservation.room_type_id">
                  <select v-bind="field" @change="selectedRoomTypeUpdateRooms()" class="regular-select">
                    <option v-for="room_type in room_types" :key="room_type.id" :value="room_type.id">
                      {{ room_type.name }}
                    </option>
                  </select>
                </v-field>
              </base-input>
              <v-error-message name="room_type" class="error-message">
              </v-error-message>
            </ion-col>

            <!-- Room -->
            <ion-col size="12" size-lg="6">
              <base-input label-text="Room *">
                <v-field name="room" v-slot="{ field }" v-model="reservation.room_id">
                  <select v-bind="field" class="regular-select">
                    <option v-for="room in rooms" :key="room.id" :value="room.id">
                      {{ room.number }}
                    </option>
                  </select>
                </v-field>
              </base-input>
              <v-error-message name="room" class="error-message">
              </v-error-message>
            </ion-col>
          </ion-row>

          <ion-row>
            <!-- Arrival Date -->
            <ion-col size="12" size-lg="4">
              <base-input label-text="Arrival Date *">
                <v-field name="arrival_date" v-slot="{ field }" v-model="reservation.arrival_date" :rules="required">
                  <ion-input name="arrival_date" v-bind="field" placeholder="" type="date" disabled></ion-input>
                </v-field>
              </base-input>
              <v-error-message name="arrival_date" class="error-message">
              </v-error-message>
            </ion-col>

            <!-- Number nights -->
            <ion-col size="12" size-lg="4">
              <base-input label-text="Number Nights *">
                <v-field name="duration" v-slot="{ field }" v-model="reservation.duration" :rules="required">
                  <select v-bind="field" class="regular-select" disabled>
                    <option :value="1">1 night</option>
                    <option :value="2">2 nights</option>
                    <option :value="3">3 nights</option>
                    <option :value="4">4 nights</option>
                    <option :value="5">5 nights</option>
                    <option :value="6">6 nights</option>
                    <option :value="7">7 nights</option>
                    <option :value="8">8 nights</option>
                    <option :value="9">9 nights</option>
                    <option :value="10">10 nights</option>
                    <option :value="11">11 nights</option>
                    <option :value="12">12 nights</option>
                    <option :value="13">13 nights</option>
                    <option :value="14">14 nights</option>
                    <option :value="15">15 nights</option>
                    <option :value="16">16 nights</option>
                    <option :value="17">17 nights</option>
                    <option :value="18">18 nights</option>
                    <option :value="19">19 nights</option>
                    <option :value="20">20 nights</option>
                    <option :value="21">21 nights</option>
                    <option :value="22">22 nights</option>
                    <option :value="23">23 nights</option>
                    <option :value="24">24 nights</option>
                    <option :value="25">25 nights</option>
                    <option :value="26">26 nights</option>
                    <option :value="27">27 nights</option>
                    <option :value="28">28 nights</option>
                    <option :value="29">29 nights</option>
                    <option :value="30">30 nights</option>
                    <option :value="31">31 nights</option>
                  </select>
                </v-field>
              </base-input>
              <v-error-message name="duration" class="error-message">
              </v-error-message>
            </ion-col>

            <ion-col size="12" size-lg="4">
              <base-input label-text="Departure Date *">
                <v-field name="departure_date" v-slot="{ field }" v-model="reservation.departure_date" :rules="required">
                  <ion-input name="departure_date" v-bind="field" placeholder="" type="date" disabled></ion-input>
                </v-field>
              </base-input>
              <v-error-message name="departure_date" class="error-message">
              </v-error-message>
            </ion-col>
          </ion-row>

          <ion-row>
            <!-- Daily Rate -->
            <ion-col size="12" size-lg="3">
              <base-input label-text="Room Daily Rate *">
                <v-field name="price_daily" v-slot="{ field }" v-model="reservation.price_daily" :rules="required">
                  <ion-input name="price_daily" v-bind="field" placeholder="0.00" type="number" disabled></ion-input>
                </v-field>
              </base-input>
              <v-error-message name="price_daily" class="error-message">
              </v-error-message>
            </ion-col>

            <!-- Retail Price -->
            <ion-col size="12" size-lg="3">
              <base-input label-text="Room Retail Total *">
                <v-field name="price_retail" v-slot="{ field }" v-model="reservation.price_retail" :rules="required">
                  <ion-input name="price_retail" v-bind="field" placeholder="0.00" type="number" disabled></ion-input>
                </v-field>
              </base-input>
              <v-error-message name="price_retail" class="error-message">
              </v-error-message>
            </ion-col>

            <!-- Discount % -->
            <ion-col size="12" size-lg="3">
              <base-input label-text="Discount % (0-100)">
                <v-field name="price_discount" v-slot="{ field }" v-model="reservation.price_discount" :rules="required">
                  <ion-input name="price_discount" v-bind="field" placeholder="0" type="number" min="0"
                    max="100"></ion-input>
                </v-field>
              </base-input>
              <v-error-message name="price_discount" class="error-message">
              </v-error-message>
            </ion-col>

            <!-- Final Price -->
            <ion-col size="12" size-lg="3">
              <base-input label-text="Guest's Price (before taxes & fees) *">
                <v-field name="price_final" v-slot="{ field }" v-model="reservation.price_final" :rules="required">
                  <ion-input name="price_final" v-bind="field" placeholder="0.00" type="number"></ion-input>
                </v-field>
              </base-input>
              <v-error-message name="price_final" class="error-message">
              </v-error-message>
            </ion-col>
          </ion-row>

          <ion-row>
            <!-- Taxes & Fees -->
            <ion-col size="12" size-lg="4">
              <base-input label-text="Estimated Taxes & Fees">
                <v-field name="price_estimated_taxes_fees" v-slot="{ field }"
                  v-model="reservation.price_estimated_taxes_fees" :rules="required">
                  <ion-input name="price_estimated_taxes_fees" v-bind="field" placeholder="0.00" type="number"
                    disabled></ion-input>
                </v-field>
              </base-input>
              <v-error-message name="price_estimated_taxes_feesx" class="error-message">
              </v-error-message>
            </ion-col>

            <!-- Estimated Total -->
            <ion-col size="12" size-lg="4">
              <base-input label-text="Estimated Total">
                <v-field name="price_estimated_total" v-slot="{ field }" v-model="reservation.price_estimated_total"
                  :rules="required">
                  <ion-input name="price_estimated_total" v-bind="field" placeholder="0.00" type="number"
                    disabled></ion-input>
                </v-field>
              </base-input>
              <v-error-message name="price_estimated_total" class="error-message">
              </v-error-message>
            </ion-col>

            <!-- Deposit -->
            <ion-col size="12" size-lg="4">
              <base-input label-text="Deposit (1 night) *">
                <v-field name="price_deposit" v-slot="{ field }" v-model="reservation.price_deposit" :rules="required">
                  <ion-input name="price_deposit" v-bind="field" placeholder="0.00" type="number"></ion-input>
                </v-field>
              </base-input>
              <v-error-message name="price_deposit" class="error-message">
              </v-error-message>
            </ion-col>
          </ion-row>

          <ion-row>
            <!-- General Notes -->
            <ion-col>
              <base-input label-text="General Notes">
                <v-field name="notes" v-slot="{ field }" v-model="reservation.general_notes">
                  <ion-textarea :auto-grow="true" rows="1" v-bind="field" placeholder="General Notes" autocapitalize
                    autocorrect>
                  </ion-textarea>
                </v-field>
              </base-input>
              <v-error-message name="notes" class="error-message">
              </v-error-message>
            </ion-col>
          </ion-row>


          <ion-button type="submit" expand="block" class="margin-x-8 margin-y-16"
            :disabled="disableCreateReservation">Create Reservation</ion-button>
        </base-card>

      </v-form>
    </div>

    <ion-modal :is-open="showUserSearchModal" @didDismiss="
      () => {
        showUserSearchModal = false;
      }
    ">
      <user-search-modal @select-user="selectUser"
        @cancel-modal="() => (this.showUserSearchModal = false)"></user-search-modal>
    </ion-modal>
  </base-layout-two>
</template>

<script>
import {
  IonGrid,
  IonRow,
  IonCol,
  IonInput,
  IonTextarea,
  IonButton,
  IonModal,
} from "@ionic/vue";
import { Field, Form, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import { mapActions } from "vuex";
import UserSearchModal from "@/components/users/UserSearchModal.vue";
import { format, addDays, differenceInHours, differenceInDays } from "date-fns";

export default {
  components: {
    IonGrid,
    IonRow,
    IonCol,
    IonInput,
    IonTextarea,
    IonButton,
    IonModal,
    UserSearchModal,
    VForm: Form,
    VField: Field,
    VErrorMessage: ErrorMessage,
  },

  computed: {
    disableCreateReservation() {
      if (
        this.reservation.arrival_date &&
        this.reservation.departure_date &&
        this.reservation.property_id &&
        this.reservation.user.id &&
        this.reservation.room_type_id &&
        this.reservation.room_id &&
        this.reservation.price_retail &&
        this.reservation.price_final
      )
        return false;

      return true;
    },
  },

  data() {
    return {
      reservation: {
        user: {},
        price_discount: 0,
        comped: 0,
        support_person_meals: 0
      },
      properties: [],
      room_types: [],
      rooms: [],
      required: yup.string().required(),
      showUserSearchModal: false,
      available_rooms: [],
      showAvailableRooms: false,
      minDepartureDate: null,
    };
  },

  watch: {
    "reservation.room_type_id": function () {
      // Set Price Retail based on Room Type Rate
      if (this.reservation.arrival_date && this.reservation.departure_date) {
        if (this.reservation.room_type_id) {
          // Find the Room Type
          let room_type = this.room_types.find(
            (x) => x.id === this.reservation.room_type_id
          );

          if (room_type && room_type.price_retail) {
            // Set the Daily Rate
            this.reservation.price_daily = room_type.price_retail;

            // Set the Deposit as 1 night or $1 for comped reservations
            if (this.reservation.comped) this.reservation.price_deposit = 1;
            else this.reservation.price_deposit = room_type.price_retail;

            // Set the Reservation Retail Total Price
            this.reservation.price_retail =
              Math.floor(differenceInHours(
                new Date(this.reservation.departure_date),
                new Date(this.reservation.arrival_date)) / 24) * room_type.price_retail;
          }
        }
      }
    },

    "reservation.price_retail": function () {
      this.reservation.price_final =
        this.reservation.price_retail *
        (1 - this.reservation.price_discount / 100);
    },

    "reservation.price_discount": function () {
      this.reservation.price_final =
        this.reservation.price_retail *
        (1 - this.reservation.price_discount / 100);
    },

    "reservation.price_final": function () {
      // Set the Estimated Taxes & fees
      // City, State, Occupncy, Javitz, TCP Porterage
      // 8.875% + 5.875% + $2.00 (x nights) + $1.50 (x nights)
      let duration = differenceInDays(new Date(this.reservation.departure_date), new Date(this.reservation.arrival_date))

      this.reservation.price_estimated_taxes_fees =
        this.reservation.price_final * 0.08875 +
        this.reservation.price_final * 0.05875 +
        (2 * duration) +
        (1.50 * duration);
      this.reservation.price_estimated_taxes_fees =
        this.reservation.price_estimated_taxes_fees.toFixed(2);

      // Set the Estimated Total
      this.reservation.price_estimated_total =
        Number(this.reservation.price_final) +
        Number(this.reservation.price_estimated_taxes_fees) +
        5.48; // Porterage Fee
      this.reservation.price_estimated_total =
        this.reservation.price_estimated_total.toFixed(2);
    },

    "reservation.arrival_date": function () {
      let minDepartureDate = addDays(
        new Date(this.reservation.arrival_date),
        2
      );

      this.minDepartureDate = format(minDepartureDate, "yyyy-MM-dd");

      // Update the departure date
      if (this.reservation.duration) {
        this.reservation.departure_date = format(
          addDays(
            new Date(this.reservation.arrival_date),
            this.reservation.duration + 1
          ),
          "yyyy-MM-dd"
        );
      }
    },

    "reservation.duration": function () {
      if (this.reservation.arrival_date) {
        this.reservation.departure_date = format(
          addDays(
            new Date(this.reservation.arrival_date),
            this.reservation.duration + 1
          ),
          "yyyy-MM-dd"
        );
      }
    },

    "reservation.comped": function () {
      if (this.reservation.comped) {
        this.reservation.price_deposit = 1;
      }
    },
  },

  async ionViewWillEnter() {
    this.setShowLoading(true);

    await this.fetchProperties();
    await this.fetchUser();

    this.setShowLoading(false);
  },

  methods: {
    ...mapActions(["loadToast", "setShowLoading"]),

    async fetchProperties() {
      await this.axios
        .get(`${process.env.VUE_APP_API}/properties`)
        .then((response) => {
          this.properties = response.data.properties;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async fetchUser() {
      if (this.$route.query.user_id) {
        await this.axios
          .get(`${process.env.VUE_APP_API}/users/${this.$route.query.user_id}`)
          .then((response) => {
            this.reservation.user_id = response.data.user.id;
            this.reservation.user = response.data.user;
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    async selectedPropertyUpdateRoomTypes() {
      // Set Room Type ID to null to force user to select a new Room Type
      this.reservation.room_type_id = null;
    },

    async selectedRoomTypeUpdateRooms() {
      // Load Rooms from Available Rooms
      this.rooms = this.available_rooms.filter(
        (x) => x.room_type_id === this.reservation.room_type_id
      );

      // Set Room ID to null to force user to select a new Room Type
      this.reservation.room_id = null;
    },

    selectUser(user) {
      this.reservation.user_id = user.id;
      this.reservation.user = user;
      this.showUserSearchModal = false;
    },

    /**
     * Fetch All Rooms
     * 
     * This is a temporary method that fetches all rooms so that manual bookings can be booked on top of one another
     * Normally we should use the SearchAvailablity method instead
     * 
     */
     async fetchAllRooms() {
      if (this.reservation.property_id) {
        this.showAvailableRooms = true;
        
        await this.axios
          .get(
            `${process.env.VUE_APP_API}/rooms/?property_id=${this.reservation.property_id}`
          )
          .then((response) => {
            this.available_rooms = response.data.rooms;

            // Sort Rooms based on Room Type
            this.available_rooms = this.available_rooms.sort(function (a, b) {
              if (a.room_type_id === b.room_type_id) {
                // Price is only important when cities are the same
                return b.number > a.number;
              }
              return a.room_type_id < b.room_type_id ? 1 : -1;
            });

            // Set Room Types as uniques from Rooms
            this.room_types = this.available_rooms
              .map((x) => x.room_type)
              .filter(
                (value, index, self) =>
                  index === self.findIndex((t) => t.id === value.id)
              );
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    async searchAvailability() {
      if (
        this.reservation.property_id &&
        this.reservation.arrival_date &&
        this.reservation.departure_date
      ) {
        this.showAvailableRooms = true;

        const config = {
          method: "post",
          url: `${process.env.VUE_APP_API}/reservations/availability`,
          data: this.reservation,
        };

        // Show a network loading indicator
        this.setShowLoading(true);

        await this.axios(config)
          .then((response) => {
            // Clear any existing data about the reservation
            this.reservation.room_type_id = null;
            this.reservation.room_id = null;
            this.reservation.price_discount = 0;
            this.reservation.price_daily = 0;
            this.reservation.price_deposit = 0;
            this.reservation.price_final = 0;
            this.reservation.price_retail = 0;
            this.reservation.price_estimated_taxes_fees = 0;
            this.reservation.price_estimated_total = 0;

            // Turn off the network loading indicator and prompt user with a toast
            this.setShowLoading(false);

            // Sort Rooms based on Room Type
            this.available_rooms = response.data.available_rooms.sort(function (
              a,
              b
            ) {
              if (a.room_type_id === b.room_type_id) {
                // Price is only important when cities are the same
                return b.number > a.number;
              }
              return a.room_type_id < b.room_type_id ? 1 : -1;
            });

            // Set Room Types as uniques from Rooms
            this.room_types = this.available_rooms
              .map((x) => x.room_type)
              .filter(
                (value, index, self) =>
                  index === self.findIndex((t) => t.id === value.id)
              );
          })
          .catch((error) => {
            console.log(error);

            // Turn off the network loading indicator and prompt user with a toast
            this.setShowLoading(false);
            this.loadToast({
              message: "Something went wrong",
              color: "danger",
            });
          });
      }
    },

    async onSubmit() {
      const config = {
        method: "post",
        url: `${process.env.VUE_APP_API}/reservations`,
        data: this.reservation,
      };

      // Show a network loading indicator
      this.setShowLoading(true);

      await this.axios(config)
        .then((response) => {
          // Turn off the network loading indicator and prompt user with a toast
          this.setShowLoading(false);
          this.loadToast({
            message: "Reservation Created",
            color: "secondary",
          });

          this.$router.replace(`/reservations/${response.data.reservation.id}`);
        })
        .catch((error) => {
          console.log(error);

          // Turn off the network loading indicator and prompt user with a toast
          this.setShowLoading(false);
          this.loadToast({ message: "Something went wrong", color: "danger" });
        });
    },
  },
};
</script>

<style scoped>
table,
thead {
  border: 1px solid #6a6a6a;
}

td {
  padding-left: 10px;
}
</style>